import React, { useState } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";

// Updated component imports without the "New" prefix
import Header1 from "../components/Header1";
import Header2 from "../components/Header2";
import Header3 from "../components/Header3";
import SectionLayout1 from "../components/SectionLayout1";
import SectionLayout2 from "../components/SectionLayout2";
import TrustedBy from "../components/TrustedBy";
import Pricing from "../components/Pricing";
import DiscoveryCall from "../components/DiscoveryCall";
import InfoCardLayouts1 from "../components/InfoCardLayouts1";
import InfoCardLayouts2 from "../components/InfoCardLayouts2";
import InfoCardLayouts3 from "../components/InfoCardLayouts3";
import InfoCardLayouts4 from "../components/InfoCardLayouts4";
import InfoCardLayouts5 from "../components/InfoCardLayouts5";
import Teams from "../components/Teams";
import BlogHeader1 from "../components/BlogHeader1";
import BlogCatalogue from "../components/BlogCatalogue";
import HeaderContactUs from "../components/HeaderContactUs";
import ContactAndForm1 from "../components/ContactAndForm1";
import VideoSection1 from "../components/VideoSection1";
import Faq from "../components/Faq";
import Gallery1 from "../components/Gallery1";
import Gallery2 from "../components/Gallery2";
import Gallery3 from "../components/Gallery3";
import DefaultPricingSection from "../components/DefaultPricingSection";
import SectionLayout3 from "../components/SectionLayout3";
import TextSection from "../components/TextSection";

const SEOTemplate = ({ data: { wpSeoPage, site, defaultPricing } }) => {
	const siteUrl = site?.siteMetadata?.siteUrl;
	const { seoFields, pageFields } = wpSeoPage;
	const [activeRole, setActiveRole] = useState(null);

	const blogHeaderData = pageFields.components.find(
		(comp) =>
			comp.fieldGroupName === "SeoPage_Pagefields_Components_BlogHeader1"
	);
	const featuredPost =
		blogHeaderData && Array.isArray(blogHeaderData.featuredBlog)
			? blogHeaderData.featuredBlog[0]
			: blogHeaderData?.featuredBlog;

	// Map each GraphQL fragment to its corresponding component
	const componentMap = {
		SeoPage_Pagefields_Components_Header1: (data) => <Header1 {...data} />,
		SeoPage_Pagefields_Components_Header2: (data) => <Header2 {...data} />,
		SeoPage_Pagefields_Components_Header3: (data) => <Header3 {...data} />,
		SeoPage_Pagefields_Components_SectionLayout1: (data) => (
			<SectionLayout1 {...data} />
		),
		SeoPage_Pagefields_Components_SectionLayout2: (data) => (
			<SectionLayout2 {...data} />
		),
		// In your page template’s componentMap:
		SeoPage_Pagefields_Components_SectionLayout3: (component) => (
			<SectionLayout3
				sections={component.sections || []}
				mobileImage={component.mobileImage}
			/>
		),

		SeoPage_Pagefields_Components_TrustedBy: (data) => <TrustedBy {...data} />,
		SeoPage_Pagefields_Components_Pricing: (data) => <Pricing {...data} />,
		SeoPage_Pagefields_Components_DefaultPricingSection: (data) => (
			<DefaultPricingSection
				fields={defaultPricing.defaultPriceSectionFields}
			/>
		),
		SeoPage_Pagefields_Components_DiscoveryCall: (data) => (
			<DiscoveryCall {...data} />
		),
		SeoPage_Pagefields_Components_InfoCardLayouts1: (data) => (
			<InfoCardLayouts1 {...data} />
		),
		SeoPage_Pagefields_Components_InfoCardLayouts2: (data) => (
			<InfoCardLayouts2 {...data} />
		),
		SeoPage_Pagefields_Components_InfoCardLayouts3: (data) => (
			<InfoCardLayouts3 {...data} />
		),
		SeoPage_Pagefields_Components_InfoCardLayouts4: (data) => (
			<InfoCardLayouts4 {...data} />
		),
		SeoPage_Pagefields_Components_InfoCardLayouts5: (data) => (
			<InfoCardLayouts5 {...data} />
		),
		SeoPage_Pagefields_Components_Teams: (data) => <Teams {...data} />,
		SeoPage_Pagefields_Components_BlogHeader1: (data) => (
			<BlogHeader1 {...data} />
		),
		SeoPage_Pagefields_Components_BlogCatalogue: (data) => (
			<BlogCatalogue featuredPost={featuredPost} {...data} />
		),
		SeoPage_Pagefields_Components_HeaderContactUs: (data) => (
			<HeaderContactUs {...data} />
		),
		SeoPage_Pagefields_Components_ContactAndForm1: (data) => (
			<ContactAndForm1 {...data} />
		),
		SeoPage_Pagefields_Components_VideoSection1: (data) => (
			<VideoSection1 {...data} />
		),
		SeoPage_Pagefields_Components_Faq: (data) => <Faq {...data} />,
		SeoPage_Pagefields_Components_Gallery1: (data) => <Gallery1 {...data} />,
		SeoPage_Pagefields_Components_TextSection: (data) => (
			<TextSection {...data} />
		),
		SeoPage_Pagefields_Components_Gallery2: (data) => <Gallery2 {...data} />,
		SeoPage_Pagefields_Components_Gallery3: (data) => <Gallery3 {...data} />,
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: siteUrl,
			},

			{
				"@type": "ListItem",
				position: 2,
				name: seoFields?.metaTitle,
				item: `${siteUrl}/${wpSeoPage.uri}`,
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.metaTitle || wpSeoPage?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/${wpSeoPage.uri}`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: seoFields?.image?.sourceUrl,
							width: seoFields?.image?.mediaDetails?.width,
							height: seoFields?.image?.mediaDetails?.height,
							alt: seoFields?.image?.altText,
						},
					],
				}}
			/>
			{pageFields.components.map((component, index) => {
				const renderComponent = componentMap[component.fieldGroupName];
				return renderComponent ? (
					<div key={index}>{renderComponent(component)}</div>
				) : null;
			})}
		</Layout>
	);
};

export default SEOTemplate;

export const query = graphql`
	query SEOById($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		defaultPricing: wpComponent(slug: { eq: "default-pricing-section" }) {
			defaultPriceSectionFields {
				body
				fieldGroupName
				smallHeading
				heading
				card1 {
					heading
					body
					button {
						target
						title
						url
					}
					addons {
						addon
					}
					items {
						item
					}
					priceAed {
						price
						underPriceText
					}
					priceEur {
						price
						underPriceText
					}
					priceGbp {
						price
						underPriceText
					}
					priceUsd {
						price
						underPriceText
					}
				}
				card2 {
					body
					heading
					button {
						target
						title
						url
					}
					addons {
						addon
					}
					items {
						item
					}
					priceAed {
						price
						underPriceText
					}
					priceEur {
						price
						underPriceText
					}
					priceGbp {
						price
						underPriceText
					}
					priceUsd {
						price
						underPriceText
					}
				}
				card3 {
					body
					heading
					addons {
						addon
					}
					button {
						target
						title
						url
					}
					items {
						item
					}
					priceAed {
						price
						underPriceText
					}
					priceEur {
						price
						underPriceText
					}
					priceGbp {
						price
						underPriceText
					}
					priceUsd {
						price
						underPriceText
					}
				}
			}
		}
		wpSeoPage(id: { eq: $id }) {
			title
			uri
			seoFields {
				opengraphTitle
				opengraphDescription
				metaTitle
				metaDescription
				fieldGroupName
				productSchema
				image {
					altText
					sourceUrl
					mediaDetails {
						height
						width
					}
				}
			}
			pageFields {
				components {
					... on WpSeoPage_Pagefields_Components_Header1 {
						body
						fieldGroupName
						heading
						button {
							title
							url
							target
						}
						button2 {
							url
							title
							target
						}
						image {
							altText
							sourceUrl
						}
						mobileImage {
							altText
							sourceUrl
						}
					}
					... on WpSeoPage_Pagefields_Components_Header2 {
						body
						fieldGroupName
						heading
						button {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
					}
					... on WpSeoPage_Pagefields_Components_Header3 {
						body
						fieldGroupName
						smallHeading
						heading
						button {
							target
							title
							url
						}
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_SectionLayout1 {
						body
						fieldGroupName
						backgroundColour
						smallHeading
						heading
						button {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_SectionLayout2 {
						body
						fieldGroupName
						smallHeading
						body2
						heading2
						heading
						button {
							target
							title
							url
						}
						column1 {
							body

							icon {
								altText
								sourceUrl
							}
						}
						column2 {
							body

							icon {
								altText
								sourceUrl
							}
						}
						column3 {
							body

							icon {
								altText
								sourceUrl
							}
						}
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						button2 {
							target
							title
							url
						}
						logos {
							logo {
								altText
								sourceUrl
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_TrustedBy {
						fieldGroupName
						heading
						businesses {
							url
							logo {
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_SectionLayout3 {
						fieldGroupName
						mobileImage {
							altText
							sourceUrl
						}
						sections {
							body
							heading
							smallHeading
							svg {
								altText
								sourceUrl
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_Pricing {
						body
						fieldGroupName
						smallHeading
						heading
						card1 {
							heading
							body
							button {
								target
								title
								url
							}
							addons {
								addon
							}
							items {
								item
							}
							priceAed {
								price
								underPriceText
							}
							priceEur {
								price
								underPriceText
							}
							priceGbp {
								price
								underPriceText
							}
							priceUsd {
								price
								underPriceText
							}
						}
						card2 {
							body
							heading
							button {
								target
								title
								url
							}
							addons {
								addon
							}
							items {
								item
							}
							priceAed {
								price
								underPriceText
							}
							priceEur {
								price
								underPriceText
							}
							priceGbp {
								price
								underPriceText
							}
							priceUsd {
								price
								underPriceText
							}
						}
						card3 {
							body
							heading
							addons {
								addon
							}
							button {
								target
								title
								url
							}
							items {
								item
							}
							priceAed {
								price
								underPriceText
							}
							priceEur {
								price
								underPriceText
							}
							priceGbp {
								price
								underPriceText
							}
							priceUsd {
								price
								underPriceText
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_DiscoveryCall {
						fieldGroupName
						body
						calendlyLink
						tab2Text
						tab1Text
						smallHeading
						heading
					}
					... on WpSeoPage_Pagefields_Components_InfoCardLayouts1 {
						backgroundColour
						body
						fieldGroupName
						heading
						cards {
							heading
							icon {
								altText
								sourceUrl
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_InfoCardLayouts2 {
						backgroundColour
						body
						fieldGroupName
						smallHeading
						heading
						button {
							target
							title
							url
						}
						cards {
							body
							heading
							icon {
								altText
								sourceUrl
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_InfoCardLayouts3 {
						backgroundColour
						body
						fieldGroupName
						heading
						smallHeading
						button {
							target
							title
							url
						}
						cards {
							body
							heading
							icon {
								altText
								sourceUrl
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_InfoCardLayouts4 {
						backgroundColour
						body
						fieldGroupName
						smallHeading
						heading
						button {
							target
							title
							url
						}
						cards {
							body
							heading
							icon {
								altText
								sourceUrl
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_InfoCardLayouts5 {
						backgroundColour
						body
						fieldGroupName
						heading
						cards {
							body
							heading
						}
					}
					... on WpSeoPage_Pagefields_Components_Teams {
						body
						fieldGroupName
						heading
						secondBody
						secondHeading
						smallHeading
						teamMembers {
							... on WpTeamMember {
								id
								title
								teamMemberFields {
									body
									role
									name
									links {
										url
										icon {
											altText
											sourceUrl
											localFile {
												childImageSharp {
													gatsbyImageData(
														formats: [WEBP, AUTO]
														quality: 100
														transformOptions: { cropFocus: CENTER, fit: COVER }
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
									image {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: FULL_WIDTH
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
						button {
							target
							title
							url
						}
					}
					... on WpSeoPage_Pagefields_Components_BlogHeader1 {
						body
						fieldGroupName
						heading
						smallHeading
						featuredBlog {
							... on WpPost {
								id
								blogFields {
									description
									featuredImage {
										altText
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
									numberOfMinsRead
								}
								title
								slug
								categories {
									nodes {
										name
									}
								}
								tags {
									nodes {
										name
									}
								}
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_BlogCatalogue {
						fieldGroupName
					}
					... on WpSeoPage_Pagefields_Components_DefaultPricingSection {
						fieldGroupName
					}
					... on WpSeoPage_Pagefields_Components_HeaderContactUs {
						body
						fieldGroupName
						heading
						smallHeading
						items {
							heading
							link {
								target
								title
								url
							}
							link2 {
								target
								title
								url
							}
							icon {
								altText
								sourceUrl
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_ContactAndForm1 {
						body
						calendlyLink
						fieldGroupName
						heading
						smallHeading
						tab1Text
						tab2Text
					}
					... on WpSeoPage_Pagefields_Components_VideoSection1 {
						body
						fieldGroupName
						smallHeading
						heading
						mediaType
						youtubeUrl
						button {
							target
							title
							url
						}
						video {
							altText
							mediaItemUrl
						}
					}
					... on WpSeoPage_Pagefields_Components_Faq {
						body
						fieldGroupName
						faqs {
							... on WpFaq {
								id
								faqFields {
									answer
									question
								}
							}
						}
						heading
						button {
							target
							title
							url
						}
					}
					... on WpSeoPage_Pagefields_Components_Gallery1 {
						body
						fieldGroupName
						smallHeading
						heading
						button {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
						galleryItems {
							url
							image {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_TextSection {
						body
						fieldGroupName
						smallHeading
						heading
						button {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
					}
					... on WpSeoPage_Pagefields_Components_Gallery2 {
						body
						fieldGroupName
						heading
						smallHeading
						button {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
						galleryItems {
							url
							price
							heading
							image {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpSeoPage_Pagefields_Components_Gallery3 {
						body
						fieldGroupName
						heading
						galleryItems {
							url
							image {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
